import {setCase} from '../GenerateSudoku.js';
import {toast} from 'react-toastify';

export function handleSolve({sudoku, setSudoku, setAnnotations}) {
    let cellList = convert(sudoku);
    if(!isSudokuPossible(cellList)) {
        toast.error('Sudoku can not be solved !');
        return ;
    }
    fullCheck(cellList);
    let start = nextEmptyCellFinder(cellList, -1);
    if (start !== -1) {
        bruteforce(cellList, start);
    }
    const newSudoku = cellList.map(obj => obj.mainDigit).join('');
    if(newSudoku.length === 81 ){
        setSudoku(newSudoku);
        setAnnotations(Array.from({ length: 81 }, () => Array(9).fill(false)));
        (sudoku !== newSudoku) && toast.success('Sudoku successfully solved !');
    } else {
        toast.error('Sudoku can not be solved !');
    }
}

export function convert(sudoku){

    let cellList = [];
    for(let index = 0; index < sudoku.length; index++){
        const cellTest = {};
        cellTest.row = Math.floor(index/9);
        cellTest.col = index%9;
        cellTest.case = setCase(cellTest.row, cellTest.col);
        cellTest.mainDigit = parseInt(sudoku[index]) === 0 ? '' : parseInt(sudoku[index]);
        cellTest.annot = cellTest.mainDigit === '' ? Array(9).fill(true) : Array(9).fill(false);
        cellList.push(cellTest);
    }

    return(cellList);
}

function isSudokuPossible(cellList) {
    for (let i = 0; i < cellList.length; ++i) {
        if (cellList
            .slice(i + 1)
            .filter(cell => cell.mainDigit !== '' && cell.mainDigit === cellList[i].mainDigit && (cell.row === cellList[i].row || cell.col === cellList[i].col || cell.case === cellList[i].case))
            .length){
                return (false);
            }
    }
    return (true);
}

function bruteforce(cellList, cellNbr) {
    //!(count%1000) ? console.log(count) : '';
    //count++;
    let nextEmptyCell = 0;
    for (let x = 0; x < 9; x++) {
        if (!isValuePossible(cellList, cellNbr, x)) {
            continue ;
        }
        cellList[cellNbr].mainDigit = x + 1;
        nextEmptyCell = nextEmptyCellFinder(cellList, cellNbr);
        if (nextEmptyCell === -1) {
            return(true);
        }
        if (bruteforce(cellList, nextEmptyCell))
            return (true);
    }
    cellList[cellNbr].mainDigit = '';
    return (false);
}

export function nextEmptyCellFinder(cellList, cellNbr) {
    for (let i = cellNbr+1; i < cellList.length; i++) {
        if (cellList[i].mainDigit === '') {
            return (i);
        }
    }
    return (-1);
}

export function isValuePossible(cellList, cellNbr, x) {
    if (cellList[cellNbr].annot[x] && isNotAnywhereElse(cellList, cellNbr, x)) {
        return (true);
    }
    return (false);
}

function isNotAnywhereElse(cellList, cellNbr, x) {
    let subList = cellList.filter(cell => cell.row === cellList[cellNbr].row || cell.col === cellList[cellNbr].col || cell.case === cellList[cellNbr].case);
   // console.log(subList);
    for (let i = 0; i < subList.length; i++) {
       // console.log(subList[i].mainDigit, x + 1);
        if (subList[i].mainDigit === x + 1) {
           // console.log("Hurrayy");
            return (false);
        }
    }
   // console.log("NOT Hurrayy");
    return (true);
}   

function fullCheck(cellList) {
    while (true) {
        initAnnotations(cellList);
        getUniqueAnnot(cellList);
        //setUniqueAnotToMain(cellList);
        if (!setUniqueAnotToMain(cellList)) {
            return ;
        }
    }
}

function initAnnotations(cellList) {
    let sublist = cellList.filter(cell => cell.mainDigit === '');
    if (sublist.length === 0) {
        return;
    }
    sublist.forEach((cell) => {
        let sublist2 = cellList.filter(cell2 => (cell2.mainDigit !== '') && (cell.row === cell2.row || cell.col === cell2.col || cell.case === cell2.case));
        sublist2.forEach((cell3) => {
            cell.annot[cell3.mainDigit - 1] = false;
        })
    })
}

function getUniqueAnnot(cellList) {
    let sublist = cellList.filter(cell => cell.mainDigit === '');
    if (sublist.length === 0) {
        return;
    }
    for (let i=0; i < 9; i++) {
        let sublistRow = sublist.filter(cell => cell.row === i);
        let sublistCol = sublist.filter(cell => cell.col === i);
        let sublistCase = sublist.filter(cell => cell.case === i);
        for (let j=0; j < 9; j++) {
            let sublistUniqueJRow = sublistRow.filter(cell => cell.annot[j] === true);
            if (sublistUniqueJRow.length === 1) {
                setAnnotToUnique(sublistUniqueJRow[0], j);
            }
            let sublistUniqueJCol = sublistCol.filter(cell => cell.annot[j] === true);
            if (sublistUniqueJCol.length === 1) {
                setAnnotToUnique(sublistUniqueJCol[0], j);
            }
            let sublistUniqueJCase = sublistCase.filter(cell => cell.annot[j] === true);
            if (sublistUniqueJCase.length === 1) {
                setAnnotToUnique(sublistUniqueJCase[0], j);
            }
        }
    }
}

function setUniqueAnotToMain(cellList) {
    let sublist = cellList.filter(cell => cell.mainDigit === '');
    if (sublist.length === 0) {
        return;
    }
    let modif = false;
    for (let i = 0; i < sublist.length; i++) {
        let subAnnotArray = sublist[i].annot.filter(cell => cell === true);
        if (subAnnotArray.length === 1) {
            let indice = findUnique(sublist[i]);
            sublist[i].mainDigit = indice + 1;
            sublist[i].annot[indice] = false;
            modif = true;
        }
    }
    return (modif);
}

function setAnnotToUnique(cell, j) {
    for (let k=0; k < 9; k++) {
        if (k !== j) {
            cell.annot[k] = false;
        }
    }
}

function findUnique(cell) {
    for (let i = 0; i < 9; i++) {
        if (cell.annot[i] === true) {
            return (i);
        }
    }
}