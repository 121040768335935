function BoxActions({handleGenerate, handleImport, handleSolve}) {
	return(
		<div className="grid grid-cols-3 gap-4">
			<Button name="GENERATE" handleSelected={handleGenerate} />
			<Button name="IMPORT" handleSelected={handleImport} />
			<Button name="SOLVE" handleSelected={handleSolve} />
		</div>
	);
}

function Button({name, handleSelected}) {
	return(
		<button onClick={handleSelected} tabIndex="-1" className="bg-gray-900 border border-gray-800 text-gray-200 text-sm md:text-xl font-normal py-2 hover:bold hover:bg-gray-700 duration-300 ">{name}</button>
	)
}

export default BoxActions;