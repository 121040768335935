import {toast} from 'react-toastify';
import { nextEmptyCellFinder, convert, isValuePossible } from './handleSolve';

async function handleGenerate({setSudoku, setAnnotations}) {
    let cellList = generateRandomSudoku();
    while (cellList.map(obj => obj.mainDigit).join('').length < 81) { cellList = generateRandomSudoku(); } // pour gérer sudoku incomplets

    let cellsToKeep = 0; //lenteur à partir de 25 -> certaines grilles ne peuvent pas avoir moins de x cells non vides mais je renvoi ce que je peux
    //il semblerait que ca n'arrive pas à faire des grilles aléatoires avec moins de 23 digits, revoir la théorie pure pour enelver des chiffres ?

    let notEmptyCells = []; //array de toutes les index des cells non vides
    for (let i = 0; i < 81; i++) {
        if(cellList[i].mainDigit !== '') {
            notEmptyCells.push(i);
        }
    }
    
    cellList = removeRandomCells(cellList, 81-cellsToKeep, notEmptyCells);

    console.clear();
    console.log(nbOfSolutions(cellList, nextEmptyCellFinder(cellList,-1), 0, 1) === 1 ? "[*] Nbr of solution(s) : 1" : "[*] Nbr of solution(s) : Multiple");
    console.log("[*] Nbr of empty cells :", cellList.filter(obj => obj.mainDigit === "").length);
    console.log("[*] Nbr of full cells :", cellList.filter(obj => obj.mainDigit !== "").length);

    // en dessous ça gère l'affichage de la grille finale
    
    toast.dismiss();
    toast.success('Sudoku successfully generated (WIP) !');
    setAnnotations(Array.from({ length: 81 }, () => Array(9).fill(false)));
    setSudoku("000000000000000000000000000000000000000000000000000000000000000000000000000000000")
    let delayres = await delay(350); //fonction pour faire un delay
    if(delayres){}; // if random pour utiliser le let et pas etre flag par VSCode
    
    setSudoku(cellList.map(obj => obj.mainDigit === "" ? "0" : obj.mainDigit).join(''));
}

function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function generateRandomSudoku() {
    let generatedSudoku = convert("000000000000000000000000000000000000000000000000000000000000000000000000000000000");
    let digitAdded = 0;

    while (digitAdded < 81) {
        let cellNumbers = randomLowestNumberOfAnnotations(generatedSudoku);
        let cellNumberIndex = Math.floor(Math.random() * cellNumbers.length);
        while (cellNumbers[cellNumberIndex] === undefined) { return generatedSudoku; } // undefined lié à une grille non complète mais impossible à finir
        putRandomPossibleNumberToCell(generatedSudoku, cellNumbers[cellNumberIndex]);
        digitAdded++;
    }
    return generatedSudoku;
}

function randomLowestNumberOfAnnotations(generatedSudoku) {
    let min = 9;
    let listIndex = [];
    for(let i = 0; i < 81; i++) {
        if(generatedSudoku[i].annot.filter(annot => annot === true).length <= min && generatedSudoku[i].annot.filter(annot => annot === true).length > 0) {
            if(generatedSudoku[i].annot.filter(annot => annot === true).length === min) {
                listIndex.push(i);
            } else {
                listIndex = [i];
                min = generatedSudoku[i].annot.filter(annot => annot === true).length;
            }
            
        }
    }
    return listIndex;
}

function putRandomPossibleNumberToCell(generatedSudoku, cellNumber) {
    if(cellNumber === -1) return;

    let indexDigit = Math.floor(Math.random() * 9);

    while(generatedSudoku[cellNumber].annot[indexDigit] === false){
        indexDigit = Math.floor(Math.random() * 9);        
    }

    generatedSudoku[cellNumber].mainDigit = indexDigit + 1;

    for(let i = 0; i < 9; i++){
        generatedSudoku[cellNumber].annot[i] = false;
    }

    updateRowColCaseAnnotations(generatedSudoku, cellNumber, indexDigit);
}

function updateRowColCaseAnnotations(generatedSudoku, cellNumber, indexDigit) {
    let filteredCells = generatedSudoku.filter(cell => cell.mainDigit === '' && (cell.row === generatedSudoku[cellNumber].row || cell.col === generatedSudoku[cellNumber].col || cell.case === generatedSudoku[cellNumber].case));
    
    filteredCells.forEach(cell => {
        let pos = cell.row * 9 + cell.col;
        generatedSudoku[pos].annot[indexDigit] = false;
    });
}

function removeRandomCells(generatedSudoku, cellsToRemove, notEmptyCells) {
    //console.log("Nbr of full cells :", notEmptyCells.length);
    if (generatedSudoku.map(cell => cell.mainDigit).join('').length === 81-cellsToRemove || cellsToRemove <= 0 || notEmptyCells.length === 0) {
        //console.log(generatedSudoku.map(cell => cell.mainDigit === '' ? "0" : cell.mainDigit).join(''));
        return generatedSudoku; // Condition de sortie de la récursion
    }

    // Sélectionner une cellule non vide au hasard
    let randomIndex = Math.floor(Math.random() * notEmptyCells.length);
    let cellNumber = notEmptyCells[randomIndex];

    // Supprimer la cellule sélectionnée et la mettre à jour
    let removedDigit = generatedSudoku[cellNumber].mainDigit;
    generatedSudoku[cellNumber].mainDigit = '';
    for (let i = 0; i < 9; i++) {
        generatedSudoku[cellNumber].annot[i] = true;
    }

    // On supprime l'index de notEmptyCells
    notEmptyCells.splice(randomIndex, 1);

    // Vérifier si la suppression entraîne toujours une grille résolvable
    let isSolvable = nbOfSolutions(generatedSudoku, nextEmptyCellFinder(generatedSudoku, -1), 0, 1) === 1;

    // Continuer la récursion en fonction du résultat de la vérification de résolvabilité
    if (!isSolvable) {
        // Si la grille n'est plus résolvable, annuler la suppression et continuer la recherche
        generatedSudoku[cellNumber].mainDigit = removedDigit;
        for (let i = 0; i < 9; i++) {
            generatedSudoku[cellNumber].annot[i] = false;
        }
    }

    //console.log(generatedSudoku.map(cell => cell.mainDigit === '' ? "0" : cell.mainDigit).join(''));
    return removeRandomCells(generatedSudoku, cellsToRemove, notEmptyCells);
}

function nbOfSolutions(cellList, cellNbr, count, maxSolutions) {
    if (cellNbr === -1) {
        count++;
        if (count > maxSolutions) {
            return count;
        }
        return count;
    }

    for (let x = 0; x < 9; x++) {
        if (isValuePossible(cellList, cellNbr, x)) {
            cellList[cellNbr].mainDigit = x + 1;
            let nextEmptyCell = nextEmptyCellFinder(cellList, cellNbr);
            count = nbOfSolutions(cellList, nextEmptyCell, count, maxSolutions);
            cellList[cellNbr].mainDigit = '';
            if (count > maxSolutions) {
                break;
            }
        }
    }

    return count;
}

// function hasMultipleSolutions(cellList, cellNbr, solutionsList) {

//     if (cellNbr === -1) {
//         solutionsList.push(JSON.parse(JSON.stringify(cellList)));
//         if (solutionsList.length > 1)
//                 return (true);
//         return (false);

//     }
//     for (let x = 0; x < 9; x++) {
//         if (!isValuePossible(cellList, cellNbr, x)) {
//             continue ;
//         }
//         cellList[cellNbr].mainDigit = x + 1;
//         let nextEmptyCell = nextEmptyCellFinder(cellList, cellNbr);
//         if (hasMultipleSolutions(cellList, nextEmptyCell, solutionsList))
//             return (true);
//     }
//     cellList[cellNbr].mainDigit = '';
//     return (false);
// }

export default handleGenerate;