export function GenerateSudoku({grid, annotations, selectedCell, handleSelectedCell}) {
    const renderSudoku = () => {
      const cells = [];
      let selectedNum = grid.slice(selectedCell[0]*9+selectedCell[1],selectedCell[0]*9+selectedCell[1]+1);
      if(selectedCell[0] === null) selectedNum = '0';
  
      for (let i = 0; i < 9; i++) {
        for (let j = 0; j < 9; j++) {
          let num = grid.slice(i*9+j,i*9+j+1);
          let k = parseInt(setCase(i,j));
          cells.push(
            <div key={`${i}-${j}-${k}`} onClick={() => handleSelectedCell(i,j,k)} className={`cell relative duration-300 border-gray-800 border
            ${selectedCell[0]===i && selectedCell[1]===j ? 'bg-gray-600 hover:bg-gray-500' : 'hover:bg-gray-700'}
            ${(selectedCell[0]===i && selectedCell[1]!==j) || (selectedCell[0] !== i && selectedCell[1] === j) || (selectedCell[0] !== i && selectedCell[1] !== j && selectedCell[2] === k) 
              ? 'bg-gray-700 hover:bg-gray-500' 
              : num === "0" || (selectedCell[0]===i && selectedCell[1]===j)
              ? '' 
              : num === selectedNum
              ? 'bg-gray-500/90' 
              : 'bg-gray-900'}    
            ${ j === 2 || j === 5 ? 'border-r-gray-500' : ''}
            ${ j === 3 || j === 6 ? 'border-l-gray-500' : ''} 
            ${ i === 2 || i === 5 ? 'border-b-gray-500' : ''}
            ${ i === 3 || i === 6 ? 'border-t-gray-500' : ''}
            `}>
              <div className="main-digit h-full w-full text-center place-content-center text-[1.75rem] font-medium absolute text-gray-200">{num === "0" ? '' : num}</div>
              <div className="annotations grid grid-cols-3 grid-rows-3 w-full aspect-square">
                {[...Array(9)].map((_, index) => (
                  <div key={index} className="annotation text-gray-200 opacity-80 text-[0.5rem] xl:text-[0.75rem] text-center">{annotations[i*9+j][index] ? index+1 : ""}</div>
                ))}
              </div>
            </div>
          );
        }
      }
  
      return cells;
    };
  
    return (
      <div className="sudoku-container overflow-hidden grid grid-cols-9 grid-rows-9 gap-0 m-0 p-0 w-full aspect-square select-none border border-gray-800">
        {renderSudoku()}
      </div>
    );
  }


  export function setCase(row, col) {
    const caseRow = row - row%3;
    const caseColumn = col - col%3;
    let index = "" + caseRow + caseColumn;
    switch (index) {
        case "00": return "0";
        case "03": return "1";
        case "06": return "2";
        case "30": return "3";
        case "33": return "4";
        case "36": return "5";
        case "60": return "6";
        case "63": return "7";
        case "66": return "8";
        default : return "";
    }
  }