import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import imgInitPaste from '../img/PasteHere.png';
import { toast } from 'react-toastify';
import { useOpenCv } from 'opencv-react';

const mountElement = document.getElementById('overlays');


export const Overlays = ({isModalOpen, setIsModalOpen, sudokuMain, setSudokuMain, setAnnotations}) => {
    return createPortal(<>{isModalOpen && <Modal setIsModalOpen={setIsModalOpen} sudokuMain={sudokuMain} setSudokuMain={setSudokuMain} setAnnotations={setAnnotations}/>}</>,mountElement)
}

const Modal = ({setIsModalOpen, sudokuMain, setSudokuMain, setAnnotations}) => {
    const [sudoku, setSudoku] = useState(sudokuMain);
    const [pasteImg, setPasteImg] = useState(imgInitPaste);

    const { cv } = useOpenCv(); 

    function setImageToCanvas() {
        const canvas = document.querySelector('canvas');
        const context = canvas.getContext('2d');
        const img = document.querySelector('img');
        canvas.width = img.width;
        canvas.height = img.height;
        context.drawImage(img, 0, 0, img.width, img.height);
    }
    
    function convertImageToSudoku({sudokuResize}) {
        toast.info("Not fully implemented yet !");
        sudokuResize();
    }

    function sudokuResize() {
        let canvas = document.querySelector('canvas');
    
        // Charger l'image à partir du canvas avec OpenCV
        let src = cv.imread(canvas);
        let dst = new cv.Mat();
    
        // Convertir l'image en niveaux de gris
        cv.cvtColor(src, dst, cv.COLOR_RGBA2GRAY);
    
        // Appliquer un flou gaussien pour réduire le bruit
        cv.GaussianBlur(dst, dst, new cv.Size(5, 5), 0, 0, cv.BORDER_DEFAULT);
    
        // Détecter les contours avec l'algorithme de Canny
        cv.Canny(dst, dst, 75, 200, 3, false);
    
        // Trouver les contours
        let contours = new cv.MatVector();
        let hierarchy = new cv.Mat();
        cv.findContours(dst, contours, hierarchy, cv.RETR_EXTERNAL, cv.CHAIN_APPROX_SIMPLE);
    
        // Trouver le contour le plus grand
        let maxArea = 0;
        let maxContourIdx = -1;
        for (let i = 0; i < contours.size(); ++i) {
            let area = cv.contourArea(contours.get(i), false);
            if (area > maxArea) {
                maxArea = area;
                maxContourIdx = i;
            }
        }
    
        // Obtenir les coordonnées de la boîte englobante du contour le plus grand
        let rect = cv.boundingRect(contours.get(maxContourIdx));
    
        // Créer un canevas pour l'image recadrée
        let croppedCanvas = document.createElement('canvas');
        let croppedCtx = croppedCanvas.getContext('2d');
    
        // Définir la taille du canevas recadré
        croppedCanvas.width = rect.width - 10;
        croppedCanvas.height = rect.height - 10;
    
        // Dessiner l'image recadrée sur le canevas recadré
        croppedCtx.drawImage(canvas, rect.x + 5, rect.y + 5, rect.width - 10, rect.height - 10, 0, 0, rect.width - 10, rect.height - 10);
    
        // Obtenez les données de l'image à partir du canevas recadré
        let croppedImageData = croppedCanvas.toDataURL('image/png');

        // Mettre à jour le state avec les données de l'image recadrée
        setPasteImg(croppedImageData);
    
        // Libérer la mémoire
        src.delete();
        dst.delete();
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
        //   event.preventDefault();
          const code = event.which || event.keyCode;
    
          let charCode = String.fromCharCode(code).toLowerCase();
          if ((event.ctrlKey || event.metaKey) && charCode === 'c') {
            //maybe
          } else if ((event.ctrlKey || event.metaKey) && charCode === 'v') {
            pasteFromClipboard();
          } 
        };
    
        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [sudoku]);
    
      const pasteFromClipboard = async () => {
        try {
            const clipboardItems = await navigator.clipboard.read();
    
            // Vérifier si le presse-papiers contient des éléments
            if (clipboardItems && clipboardItems.length > 0) {
                // Obtenir le premier élément du presse-papiers
                const clipboardItem = clipboardItems[0];
    
                // Vérifier si le type 'image/png' est pris en charge
                if (clipboardItem.types.includes('image/png')) {
                    // Obtenir les données de l'image
                    const blobOutput = await clipboardItem.getType('image/png');
                    const data = URL.createObjectURL(blobOutput);
                    setPasteImg(data);
                    toast.success('Image successfully imported !');
                } else {
                    // Vérifier si le type 'text/plain' est pris en charge
                    if (clipboardItem.types.includes('text/plain')) {
                        // Obtenir le texte en tant que Blob
                        const blobText = await clipboardItem.getType('text/plain');
                        
                        // Convertir le Blob en texte
                        const text = await blobText.text();
                        
                        // Définir le texte dans l'état
                        if(text !== sudoku && /^\d+$/.test(text)){
                            setSudoku(text);
                            toast.success('Text successfully imported !');
                        }
                        
                    } else {
                        // Aucun type pris en charge
                        toast.error('Clipboard does not contain supported content types.');
                    }
                }
            } else {
                toast.error('Clipboard is empty.');
            }
        } catch(e) {
            toast.error('Error importing content from clipboard.');
            console.error(e);
        }
    }
     
    
    

    return (
        <div onClick={() => setIsModalOpen(false)} className='modal fixed top-0 left-0 w-full h-full bg-black bg-opacity-50'>
            <div onClick={(e) => e.stopPropagation()} className='modal-content text-gray-200 relative left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-gray-800 w-1/2 min-w-[300px] md:min-w-[500px] max-h-full p-6'>
                <button onClick={() => setIsModalOpen(false)} className='close duration-300 absolute top-1 right-1 text-3xl bg-gray-950 hover:bg-gray-950/85 px-2.5 pb-1.5 border border-gray-800'>&times;</button>
                <h2 className='text-2xl md:text-4xl text-center font-semibold pb-6'>Import a Sudoku</h2>
                <p className='text-sm md:text-xl text-justify italic bg-gray-700/85 border border-gray-600 p-5'>
                Paste an image of a Sudoku grid into the designated area below, try to screenshot only the grid, then click <span className='bg-gray-900 border border-gray-700/70 text-gray-200 px-3 not-italic inline-block'>FROM IMAGE</span> button and the tool will automatically detect the grid and import it for you. 
                Alternatively, you can import your grid by entering a sequence of 81 characters from 0 to 9, representing the Sudoku grid values in order, using 0 for the empty cells, then click on <span className='bg-gray-900 border border-gray-700/70 text-gray-200 px-3 not-italic inline-block'>FROM TEXT</span> button.
                </p>
                <div className='grid grid-cols-2 gap-10 justify-center items-center my-8'>
                    <canvas onClick={pasteFromClipboard} className='w-full aspect-square border border-gray-700/70'></canvas>
                    <div className='flex flex-col gap-3 h-full text-nowrap'>
                        <button onClick={() => convertImageToSudoku({sudokuResize})}className="bg-gray-900 border border-gray-700/70 text-gray-200 flex-grow text-sm md:text-2xl font-normal hover:font-semibold hover:lg:text-[1.75rem] hover:bg-gray-700 duration-300 before:content-['FROM_']">IMAGE</button>
                        <button onClick={() => {
                            if(sudoku !== sudokuMain && sudoku.length === 81 && /^\d+$/.test(sudoku)){
                                setSudokuMain(sudoku);
                                setAnnotations(Array.from({ length: 81 }, () => Array(9).fill(false)));
                                setIsModalOpen(false);
                                toast.success('Sudoku successfully imported !')
                            }
                            else if (sudoku === sudokuMain){
                                toast.warning('Sudoku has not changed !');
                            }
                            else if (!/^\d+$/.test(sudoku)){
                                toast.error('Sudoku contains invalid characters, please enter numbers only !');
                            }
                            else if(sudoku.length !== 81){
                                toast.error(`You've entered ${sudoku.length} characters, please enter 81 to have a complete Sudoku`);
                            }
                        }} className="bg-gray-900 border border-gray-700/70 text-gray-200 flex-grow text-sm md:text-2xl font-normal hover:font-semibold hover:lg:text-[1.75rem] hover:bg-gray-700 duration-300 before:content-['FROM_']">TEXT</button>
                    </div>
                </div>
                <input type="text" name="sudoku_input" placeholder="Type your Sudoku here" value={sudoku} onChange={(e) => setSudoku(e.target.value)} className='w-full border border-gray-700/70 bg-gray-900 text-gray-200 text-sm lg:text-lg font-normal text-center p-2 hover:bg-gray-900/80 duration-300 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none focus:outline-none' /> 
                <img onLoad={setImageToCanvas} src={pasteImg} alt="Paste Here" className='invisible fixed top-0 left-0 h-1/2 w-auto' />
            </div>
        </div>
    )
}