function BoxDigits({mainDigitMode, handleMode, handleSelected, selectedCell, updateAnnotations}) {

    return (
      <div className="box-digits grid grid-cols-10 width-full border border-gray-800 bg-gray-900 my-4">
        <Button digit='1' mainDigitMode={mainDigitMode} handleSelected={() => handleSelected(1)} updateAnnotations={() => updateAnnotations(selectedCell[0],selectedCell[1],1)} />
        <Button digit='2' mainDigitMode={mainDigitMode} handleSelected={() => handleSelected(2)} updateAnnotations={() => updateAnnotations(selectedCell[0],selectedCell[1],2)} />
        <Button digit='3' mainDigitMode={mainDigitMode} handleSelected={() => handleSelected(3)} updateAnnotations={() => updateAnnotations(selectedCell[0],selectedCell[1],3)} />
        <Button digit='4' mainDigitMode={mainDigitMode} handleSelected={() => handleSelected(4)} updateAnnotations={() => updateAnnotations(selectedCell[0],selectedCell[1],4)} />
        <Button digit='5' mainDigitMode={mainDigitMode} handleSelected={() => handleSelected(5)} updateAnnotations={() => updateAnnotations(selectedCell[0],selectedCell[1],5)} />
        <Button digit='6' mainDigitMode={mainDigitMode} handleSelected={() => handleSelected(6)} updateAnnotations={() => updateAnnotations(selectedCell[0],selectedCell[1],6)} />
        <Button digit='7' mainDigitMode={mainDigitMode} handleSelected={() => handleSelected(7)} updateAnnotations={() => updateAnnotations(selectedCell[0],selectedCell[1],7)} />
        <Button digit='8' mainDigitMode={mainDigitMode} handleSelected={() => handleSelected(8)} updateAnnotations={() => updateAnnotations(selectedCell[0],selectedCell[1],8)} />
        <Button digit='9' mainDigitMode={mainDigitMode} handleSelected={() => handleSelected(9)} updateAnnotations={() => updateAnnotations(selectedCell[0],selectedCell[1],9)} />
        <button onClick={handleMode} tabIndex="-1" className="relative p-2 text-gray-200 hover:bg-gray-800 text-2xl font-semibold opacity-100 text-center duration-300">
          <svg xmlns="http://www.w3.org/2000/svg" className = "w-fit aspect-square"  viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 8l4 -4l4 4" /><path d="M7 4l0 9" /><path d="M13 16l4 4l4 -4" /><path d="M17 10l0 10" /></svg>
        </button>
      </div>
    );
  }
  
  function Button({ digit, mainDigitMode, handleSelected, updateAnnotations }) {
    return (
      <button onClick={() => {mainDigitMode && updateAnnotations(); handleSelected();}} tabIndex="-1" className={`relative ${ !mainDigitMode ? 'text-gray-500' : 'text-gray-200'} hover:bg-gray-800 text-2xl font-semibold opacity-100 pb-3 pr-1 duration-300`}>
        {digit}
        <span className={`${!mainDigitMode ? 'text-gray-200' : 'text-gray-500'} text-xs absolute bottom-0.5 md:bottom-1.5 right-2 duration-400`}>
          {digit}
        </span>
      </button>
    );
  }

  export default BoxDigits;