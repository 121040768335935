import './App.css';
import React, { useState, useEffect } from 'react';
import { OpenCvProvider } from 'opencv-react';
import {GenerateSudoku, setCase} from './GenerateSudoku.js';
import BoxDigits from './BoxDigits.js';
import BoxActions from './BoxActions.js';
import handleGenerate from './scripts/handleGenerate.js';
import { Overlays } from './scripts/handleImport.js';
import { handleSolve } from './scripts/handleSolve.js';

import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function App() {
  let sudokuTest = "581000000000005300000090500000000802000000040307020000005060980700400003040001000"; //293 solutions
  const [sudoku, setSudoku] = useState(sudokuTest);
  const [annotations, setAnnotations] = useState(Array.from({ length: 81 }, () => Array(9).fill(false)));
  const [mainDigitMode, setMainDigitMode] = useState(true);
  const [selectedCell, setSelectedCell] = useState([null,null,null]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const handler = (e) => {
      if(e.key === "1" || e.key === "2" || e.key === "3" || e.key === "4" || e.key === "5" || e.key === "6" || e.key === "7" || e.key === "8" || e.key === "9"){
        mainDigitMode && updateAnnotations(selectedCell[0],selectedCell[1],e.key);
        setDigitToSellectedCell(parseInt(e.key));
      }
      if(e.key === "Backspace" || e.key === "-" || e.key === "0" || e.key === "." || e.key === "Delete" ){
        let pos = selectedCell[0]*9+selectedCell[1];
        let sudokuUpdated = sudoku.slice(0, pos) + "0" + sudoku.slice(pos+1);
        setSudoku(sudokuUpdated);
      }
      if(e.key === "ArrowUp" || e.key === "z"){
        let newSelectedCell = selectedCell.slice();
        if(newSelectedCell[0]>0 && selectedCell[1] !== null){
          newSelectedCell[0]--;
          newSelectedCell[2] = parseInt(setCase(newSelectedCell[0], newSelectedCell[1]));
          setSelectedCell(newSelectedCell);
        }
      }
      if(e.key === "ArrowDown" || e.key === "s"){
        let newSelectedCell = selectedCell.slice();
        if(newSelectedCell[0]<8 && selectedCell[1] !== null){
          newSelectedCell[0]++;
          newSelectedCell[2] = parseInt(setCase(newSelectedCell[0], newSelectedCell[1]));
          setSelectedCell(newSelectedCell);
        }
      }
      if(e.key === "ArrowLeft" || e.key === "q"){
        let newSelectedCell = selectedCell.slice();
        if(newSelectedCell[1]>0 && selectedCell[1] !== null){
          newSelectedCell[1]--;
          newSelectedCell[2] = parseInt(setCase(newSelectedCell[0], newSelectedCell[1]));
          setSelectedCell(newSelectedCell);
        }
      }
      if(e.key === "ArrowRight" || e.key === "d"){
        let newSelectedCell = selectedCell.slice();
        if(newSelectedCell[1]<8 && selectedCell[1] !== null){
          newSelectedCell[1]++;
          newSelectedCell[2] = parseInt(setCase(newSelectedCell[0], newSelectedCell[1]));
          setSelectedCell(newSelectedCell);
        }
      }
      if(e.key === "Tab" || e.key === "+" || e.key === "Enter" || e.key === " "){
        e.preventDefault();
        setMainDigitMode(!mainDigitMode);
      }
      // console.log(e.key);
    };
    if (!isModalOpen) {
      document.addEventListener('keydown', handler, true);
    }

    return () => {
        if (!isModalOpen) {
            document.removeEventListener('keydown', handler, true);
        }
      }
    // ligne suivante pour désactiver un warning lié au fait que setDigitToSellectedCell() n'est pas dans les dépendances -> à priori c'est normal
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCell, mainDigitMode, sudoku]);

  function handleMode() {
    setMainDigitMode(!mainDigitMode);
  }

  function handleIsModalOpen() {
    setIsModalOpen(!isModalOpen);
  }

  function setDigitToSellectedCell(i) {
    if(selectedCell[0] === null){
      return;
    }
    let pos = selectedCell[0]*9+selectedCell[1];
    let annotationsUpdated = annotations.slice();
    if (mainDigitMode) {
      let checkToggle = (parseInt(sudoku.slice(pos, pos+1)) === i) ? 0 : i;
      let sudokuUpdated=sudoku.slice(0, pos) + checkToggle + sudoku.slice(pos+1);
      setSudoku(sudokuUpdated);
      annotationsUpdated[pos] = Array(9).fill(false);
      setAnnotations(annotationsUpdated);
    } else {
      if(!parseInt(sudoku.slice(pos, pos+1))){
        annotationsUpdated[pos][i-1] = !annotationsUpdated[pos][i-1];
        setAnnotations(annotationsUpdated);
      } else {
        return;
      }
    }
  }

  function handleSelectedCell(i,j,k) {
    if(selectedCell[0] === i && selectedCell[1] === j && selectedCell[2] === k){
      setSelectedCell([null,null,null]);
    }else{
      setSelectedCell([i,j,k]);
    }
  }

  function updateAnnotations (row, col, digit) {
    if (parseInt(sudoku[row*9+col]) === digit) return;
    let annotationsUpdated = annotations.slice();
    for (let rowIndex = 0; rowIndex < 9; rowIndex++) {
      annotationsUpdated[rowIndex*9+col][digit-1] = false;
    }
    for (let colIndex = 0; colIndex < 9; colIndex++) {
      annotationsUpdated[row*9+colIndex][digit-1] = false;
    }
    let boxRow = Math.floor(row / 3) * 3;
    let boxCol = Math.floor(col / 3) * 3;
    for (let rowIndex = 0; rowIndex < 3; rowIndex++) {
      for (let colIndex = 0; colIndex < 3; colIndex++) {
        annotationsUpdated[(boxRow+rowIndex)*9+(boxCol+colIndex)][digit-1] = false;
      }
    }
    setAnnotations(annotationsUpdated);
  }

  return (
    <>
    <OpenCvProvider>
    <Overlays isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} sudokuMain={sudoku} setSudokuMain={setSudoku} setAnnotations={setAnnotations}/>
    <div className="bg-[#030712] w-full grid place-content-center gap-0 content-start px-4">
      <h1 className='text-5xl md:text-6xl text-gray-200 text-center font-bold pt-9 pb-7 w-full md:w-[34rem]'>Sudoku Solver</h1>
      <GenerateSudoku grid={sudoku} annotations={annotations} selectedCell={selectedCell} handleSelectedCell={handleSelectedCell} />
      <BoxDigits mainDigitMode={mainDigitMode} handleMode={handleMode} handleSelected={setDigitToSellectedCell} selectedCell={selectedCell} updateAnnotations={updateAnnotations} />
      <BoxActions handleGenerate={() => handleGenerate({setSudoku, setAnnotations})} handleImport={handleIsModalOpen} handleSolve={() => handleSolve({sudoku, setSudoku, setAnnotations})}/>
      <ToastContainer position="top-center" autoClose={2000} hideProgressBar={true} newestOnTop={false} limit={2} closeOnClick rtl={false} pauseOnFocusLoss={false} draggable pauseOnHover theme="dark" transition={Slide}/>
    </div>
    </OpenCvProvider>
    </>
  )
}

